import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../i18n';
import Grid from '@mui/material/Grid';
import Controls from "../components/controls/Controls";
import { useForm, Form } from '../components/useForm';
import Container from '@mui/material/Container';
import axios from 'axios';
import Constants from '../conf/constants';
import Accordion from '@mui/material/Accordion';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const tipologiaartista=[{id:"altro"},
{id:"attorecomicospettacolo"},
{id:"fotografo"},
{id:"musicista"},
{id:"performer"},
{id:"pittore"},
{id:"scenografo"},
{id:"scultore"}]

const initialFValues = {
nome:'',
cognome:'',
email:'',
tipologiaartista:'',
citta:'',
cap:'',
provincia:'',
noteaggiuntive:'',
filepresentazione:''
}

export default function Artisti() {
    const { t, i18n, ready } = useTranslation( 'translation',{ useSuspense:false});

    const[presentationFile,setPresentationFile]=useState(null)
    const [alertConf,setAlertConf]=React.useState();
    const [openDialog,setOpenDialog]=useState(false);

    const messages_info_box={
      "ARTIST_EXISTS":t("ARTIST_EXISTS"),
      "ARTIST_ERRORS":t("ARTIST_ERRORS"),
      "ARTIST_SUCCESS":t("ARTIST_SUCCESS")
  }

  const handleClose=()=>{
    setOpenDialog(false);
    setAlertConf();
    window.location.href="https://prc-srl.com";
    //window.location.replace("https://prc-srl.com");//non entra in hitstory
}
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('nome' in fieldValues)
            temp.nome = fieldValues.nome ? "" : i18n.t('errore_nome')
        if ('cognome' in fieldValues)
            temp.cognome = fieldValues.cognome ? "" : i18n.t('errore_cognome')
        if ('email' in fieldValues)
            temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : i18n.t('errore_email')
        if ('tipologiaartista' in fieldValues)
            temp.tipologiaartista = fieldValues.tipologiaartista ? "" : i18n.t('errore_tipologiaartista')
        if ('citta' in fieldValues)
            temp.citta = fieldValues.citta ? "" : i18n.t('errore_citta')
        if ('cap' in fieldValues)
            temp.cap = fieldValues.cap.length > 4 ? isNaN(fieldValues.cap.trim()) ? "C.A.P. non valido":"":fieldValues.cap.length > 0? "C.A.P. non valido": "Richiesto"
        if ('provincia' in fieldValues)
            temp.provincia = fieldValues.provincia ? "" : i18n.t('errore_provincia')                       
        setErrors({
            ...temp
        })
 
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()){
            const formData = new FormData();
            formData.append("jsondata", JSON.stringify(values));
            if(presentationFile!==null)
                formData.append('file', presentationFile);
            const config = {
              headers: {
                'content-type': 'multipart/form-data'
              }
            }

            axios.post(Constants.REGISTRAZIONE_ARTISTA,formData,config)
            .then(response=>{console.log(response)
                setAlertConf(response.data.message);
                setOpenDialog(true);
            })
            .catch(error=>{console.log(error)})
            resetForm()
            setPresentationFile(null)
        }
    }

    //window.location.reload(true);

    const updateUploadedFile = (file) =>{
        setPresentationFile(file.files[0]);
    }



    return (
        <Form onSubmit={handleSubmit}>
                  <Box
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: 'white',
          marginTop:"5px"
        }}
      >
                  <Box
                  mt={2}
                  sx={{
                    width: '100%',
                  }}
                > 
          <Accordion expanded={true} >
          <Typography align='center' variant="h5" sx={{ flexShrink: 0}}>
                Artisti
              </Typography>
          <Box mt={5} >
        <Grid container>
            <Grid item xs={6} sx={{ display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'}}>
                <Controls.Input
                    name="nome"
                    label={t('nome')}
                    value={values.nome}
                    onChange={handleInputChange}
                    required={true}
                    error={errors.nome}
                />
                      
                <Controls.Input
                    name="email"
                    label={t('email')}
                    value={values.email}
                    onChange={handleInputChange}
                    required={true}
                    error={errors.email}
                />
                  <Controls.Input
                    name="citta"
                    label={t('citta_residenza')}
                    value={values.citta}
                    onChange={handleInputChange}
                    required={true}
                    error={errors.citta}
                />

                <Controls.Input
                    name="provincia"
                    label={t('provincia')}
                    value={values.provincia}
                    onChange={handleInputChange}
                    required={true}
                    error={errors.provincia}
                />

                <Controls.FilePicker
                    name="filepresentazione"
                    label={t('filepresentazione')}
                    value={values.filepresentazione}
                    onChange={handleInputChange}
                    callbackOnChange={updateUploadedFile}
                    required={false}
                    maxSizeMb={0}
                    fileType="application/pdf"
                    error={errors.filepresentazione}
               />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'}}>

            <Controls.Input
                    name="cognome"
                    label={t('cognome')}
                    value={values.cognome}
                    onChange={handleInputChange}
                    required={true}
                    error={errors.cognome}
                />
               <Controls.Select
                    name="tipologiaartista"
                    label={t('tipologiaartista')}
                    value={values.tipologiaartista}
                    onChange={handleInputChange}
                    options={tipologiaartista}
                    required={true}
                    error={errors.tipologiaartista}
               />
               <Controls.Input name="cap" label={t('cap')} type="text" value={values.cap} required={true} onChange={handleInputChange} error={errors.cap}/>

                  <Controls.Input
                    name="noteaggiuntive"
                    label={t('noteaggiuntive')}
                    value={values.noteaggiuntive}
                    onChange={handleInputChange}
                    error={errors.noteaggiuntive}
                />


            </Grid>
        </Grid>
        </Box>
        <Box mt={5} sx={{
            display: 'flex',
            flexWrap: 'no-wrap',
            justifyContent: 'center',
            marginBottom:'10px'
          }}>
                    <Controls.Button
                        type="submit"
                        text="Invia" />
                </Box>
                
                </Accordion>
                </Box>
                </Box>
                <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{display:'flex',justifyContent: "space-around"}}>
               {alertConf==='ARTIST_SUCCESS'?<CheckCircleOutlineIcon sx={{fill:'rgb(162, 146, 86)',width:'3em', height:'3em'}}/>:<WarningAmberIcon sx={{fill:'rgb(162, 146, 86)',width:'3em', height:'3em'}} />}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {messages_info_box[alertConf]}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-around"}}>
        <Controls.Button onClick={handleClose} text="CONFERMA"/>
        </DialogActions>
      </Dialog>
    
    </Form>

    );
  }

import React from 'react'

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField from '@mui/material/TextField';

export default function DatePicker(props) {

    const { name, label, disabled=false,value, required,error=null,onChange } = props



    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    function getMonthFromString(mon){
        return new Date(Date.parse(mon +" 1, 1970")).getMonth()+1;
     }

    const dateToString = (date) => {
        var result='';
        try{
           var dateFields=date.toString().split(' ');
            result=dateFields[3]+'-'+getMonthFromString(dateFields[1])+'-'+dateFields[2];
           //var dateFields=date.toLocaleDateString('it-IT').split('/');
           //result=dateFields[2]+'-'+dateFields[1]+'-'+dateFields[0];

        }catch (error){
            //console.error(error);
            result='';
        }finally{
            return result;
        }    
    }

	//const dateToString=(date)=>{ return date.toLocaleDateString('it-IT') }
  
    return (
	    <LocalizationProvider dateAdapter={AdapterDateFns}> 
            <DesktopDatePicker disableToolbar variant="inline" inputVariant="outlined"
                label={label}
                inputFormat="dd/MM/yyyy"
                name={name}
                value={value}
	        disabled={disabled}
	    disableOpenPicker={true}
                onChange={date =>onChange(convertToDefEventPara(name,dateToString(date)))}
	        allowSameDateSelection={true}
                renderInput={(params) =>{params.error=params.error&&value.length>0; return(<TextField name={name} {...params} label={label} required={required}  {...(error && {error:true,helperText:error})}/>)}}
            />
	    </LocalizationProvider>
    )
}
